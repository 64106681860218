<!-- Don't forget to include this: https://fonts.googleapis.com/icon?family=Material+Icons -->
<!-- See: https://fonts.google.com/icons for complete icon list -->
<template>
    <span class="material-icons icon-style" :style=iconStyle>
        <slot></slot>
    </span>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';

const props = defineProps({
    size: Number
});

const iconSize = ref(props.size ? props.size : 20);
const iconStyle = ref({});

// We have to do this since mdi icons a slightly off in the y position when the fontsize is smaller then 20px
// so we have to manually nudge the icon 2px up to be vertically centered again
watchEffect(() => {
    iconStyle.value = {
        fontSize: `${iconSize.value}px`,
        transform: iconSize.value <= 20 ? 'translateY(-2px)' : 'none'
    };
});

</script>

<style scoped>
.icon-style {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    line-height: 1;
}
</style>
